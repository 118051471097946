/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Router from 'vue-router';
import LoginService from '@/Services/LoginService';
import Login from '@/components/global/login.vue';
// import { AclRule } from 'vue-acl';
import Home from './views/Home.vue';

Vue.use(Router);

export const children = [
  {
    path: '/agentes',
    name: 'agents',
    component: () => import('@/components/pages/agents/list-agents.vue'),
    meta: {
      title: 'Agentes de venda',
      rule: 'isInternal',
    },
  },
  {
    path: '/agentes/adicionar',
    name: 'agents-create',
    component: () => import('@/components/pages/agents/create-agent.vue'),
    meta: {
      rule: 'isInternal',
      title: 'Adicionar novo agente de vendas',
    },
  },
  {
    path: '/agentes/editar/:id',
    name: 'agents-edit',
    props: true,
    component: () => import('@/components/pages/agents/create-agent.vue'),
    meta: {
      rule: 'isInternal',
      title: 'Adicionar novo agente de vendas',
    },
  },
  {
    path: '/clientes',
    name: 'clients',
    component: () => import('@/components/pages/clients/list-clients.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Clientes',
    },
  },
  {
    path: '/clientes/adicionar',
    name: 'clients-create',
    component: () => import('@/components/pages/clients/create-client.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo cliente',
    },
  },
  {
    path: '/clientes/editar/:id',
    name: 'client-edit',
    props: true,
    component: () => import('@/components/pages/clients/create-client.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Editar cliente',
    },
  },
  {
    path: '/projeto/adicionar',
    name: 'project-create',
    component: () => import('@/components/pages/project/create-project.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo pré projeto',
    },
  },
  {
    path: '/projeto/adicionar/:client_id',
    name: 'project-create-with-client',
    props: true,
    component: () => import('@/components/pages/project/create-project.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo pré projeto',
    },
  },
  {
    path: '/projetos',
    name: 'projects',
    component: () => import('@/components/pages/project/list-projects.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Projetos',
    },
  },
  {
    path: '/projeto/:id',
    name: 'project',
    props: true,
    component: () => import('@/components/pages/project/project.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Projeto',
    },
  },
  {
    path: '/projeto-customizado/:id',
    name: 'project-custon',
    props: true,
    component: () => import('@/components/pages/project/project-custon.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Projeto',
    },
  },
  {
    path: '/projeto/:project/editar-kit',
    name: 'project-edit-kit',
    props: true,
    component: () => import('@/components/pages/project/edit-kit.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Editar Kit',
    },
  },
  {
    path: '/vistorias',
    name: 'inspections',
    component: () => import('@/components/pages/inspections/list-inspections.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Vistorias Técnicas',
    },
  },
  {
    path: '/vistoria/:id',
    props: true,
    name: 'inspection-edit',
    component: () => import('@/components/pages/inspections/inspection.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Vistoria Técnica',
    },
  },
  {
    path: '/pre-vistoria/:id',
    props: true,
    name: 'pre-inspection',
    component: () => import('@/components/pages/inspections/pre-inspection.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Vistoria',
    },
  },
  {
    path: '/financiamentos',
    name: 'financings',
    component: () => import('@/components/pages/financings/list-financings.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Financiamentos',
    },
  },
  {
    path: '/financiamento/adicionar/:client_id',
    props: true,
    name: 'financing-create',
    component: () => import('@/components/pages/financings/create-financing.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo financiamento',
    },
  },
  {
    path: '/usuarios',
    name: 'users',
    component: () => import('@/components/pages/users/list-users.vue'),
    meta: {
      rule: 'isInternal',
      title: 'Usuários cadastrados',
    },
  },
  {
    path: '/administrativo',
    name: 'admin',
    component: () => import('@/components/pages/admin/admin.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Administrativo',
    },
  },
  {
    path: '/inversores',
    name: 'inverter',
    component: () => import('@/components/pages/inverter/inverter.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Inversores',
    },
  },
  {
    path: '/inversor/adicionar',
    name: 'inverter-create',
    component: () => import('@/components/pages/inverter/inverter-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar inversor',
    },
  },
  {
    path: '/inversor/editar/:id',
    name: 'inverter-edit',
    props: true,
    component: () => import('@/components/pages/inverter/inverter-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Editar inversor',
    },
  },
  {
    path: '/painel',
    name: 'panel',
    component: () => import('@/components/pages/panel/panel.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Painéis',
    },
  },
  {
    path: '/painel/adicionar',
    name: 'panel-create',
    component: () => import('@/components/pages/panel/panel-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar Painel',
    },
  },
  {
    path: '/painel/editar/:id',
    name: 'panel-edit',
    props: true,
    component: () => import('@/components/pages/panel/panel-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Editar Painel',
    },
  },
  {
    path: '/transformadores',
    name: 'transformers',
    component: () => import('@/components/pages/transformer/transformer.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Transformador',
    },
  },
  {
    path: '/transformador/adicionar',
    name: 'transformer-create',
    component: () => import('@/components/pages/transformer/transformer-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar Transformador',
    },
  },
  {
    path: '/transformador/editar/:id',
    name: 'transformer-edit',
    props: true,
    component: () => import('@/components/pages/transformer/transformer-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Editar Transformador',
    },
  },
  {
    path: '/estrutura',
    name: 'structure',
    component: () => import('@/components/pages/structure/structure.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Estrutura',
    },
  },
  {
    path: '/estrutura/adicionar',
    name: 'structure-create',
    component: () => import('@/components/pages/structure/structure-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar Estrutura',
    },
  },
  {
    path: '/estrutura/editar/:id',
    name: 'structure-edit',
    props: true,
    component: () => import('@/components/pages/structure/structure-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Editar Estrutura',
    },
  },
  {
    path: '/componentes',
    name: 'components',
    props: true,
    component: () => import('@/components/pages/components/list-components.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Componentes',
    },
  },
  {
    path: '/componentes/adicionar',
    name: 'components-create',
    component: () => import('@/components/pages/components/components-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar Componente',
    },
  },
  {
    path: '/componentes/editar/:id',
    name: 'components-edit',
    props: true,
    component: () => import('@/components/pages/components/components-create.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Editar Componente',
    },
  },
];

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
// });
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
        rule: 'isPublic',
      },
      children,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        rule: 'isPublic',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = LoginService.getToken();

  if (!token && to.name !== 'login') {
    return next({ name: 'login' });
  }

  if (token && (to.name === 'login' || to.fullPath === '/login')) {
    return next({ path: '/' });
  }

  if (token) {
    const decoded = LoginService.getDecodedToken();

    if (!decoded) {
      LoginService.errorDecoded();

      return next({ name: 'login' });
    }
  }

  return next();
});

export default router;
