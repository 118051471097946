import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from './router';

Vue.use(AclInstaller);

export default new AclCreate({
  initial: 'admin',
  notfound: {
    path: '/error',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isAdmin: new AclRule('admin').generate(),
    isPublic: new AclRule('public').or('admin').or('internal').generate(),
    isLogged: new AclRule('user').and('inside').generate(),
    isInternal: new AclRule('internal').or('admin').generate(),
  },
  middleware: (acl) => {
    const permission = localStorage.getItem('permission') || 'admin';
    acl.change(permission);
  },
});
