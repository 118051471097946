/* eslint-disable import/no-cycle */
import EventBus from '@/util/event-bus';
import jwtDecode from 'jwt-decode';
import http from '../http';
import router from '../router';

class LoginService {
  constructor() {
    this.tokenKey = 'token';
    this.picKey = 'pic';
    this.user = 'user';
    this.user_id = 'user.id';
    this.loginKey = 'login';
    this.permissions = 'permission';
    this.rules = 'rules';
  }

  // eslint-disable-next-line class-methods-use-this
  login(payload) {
    return http.post('/login', payload);
  }

  clear() {
    this.saveAndClear();
    router.replace('/login');
  }

  errorDecoded() {
    EventBus.$emit('error', { title: 'Ocorreu um erro na decodificação do token', subtitle: '' });
    this.clear();
  }

  // eslint-disable-next-line class-methods-use-this
  saveAndClear() {
    const version = localStorage.getItem('version');
    const items = localStorage.getItem('items');
    localStorage.clear();
    localStorage.setItem('version', version);
    localStorage.setItem('items', items);
  }

  isSE() {
    const { sub } = this.getDecodedToken();

    return !!sub.se;
  }

  logout(expired = false) {
    this.saveAndClear();

    if (!expired) return;

    EventBus.$emit('warning', { title: 'Sua sessão expirou!', subtitle: 'Realize o login novamente' });
  }

  getPic() {
    return localStorage.getItem(this.picKey);
  }

  getDisplayName() {
    return localStorage.getItem(this.user.name);
  }

  getUserId() {
    return localStorage.getItem(this.user_id);
  }

  getLogin() {
    const decoded = this.getDecodedToken();

    return (decoded && decoded.sub)
      ? decoded.sub.username
      : '';
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  getDecodedToken() {
    const token = localStorage.getItem(this.tokenKey);
    const JWT_REGEX = /^[a-zA-Z0-9\-_]+?\.[a-zA-Z0-9\-_]+?\.([a-zA-Z0-9\-_]+)?$/;

    return (token && JWT_REGEX.test(token))
      ? jwtDecode(token)
      : false;
  }

  saveToken(payload) {
    localStorage.setItem(this.tokenKey, payload);
  }

  savePermission(payload) {
    localStorage.setItem(this.permissions, payload);
  }

  saveRules(payload) {
    localStorage.setItem(this.rules, payload);
  }

  saveUser(user) {
    localStorage.setItem(this.user, JSON.stringify(user));
  }
}

const loginService = new LoginService();
export default loginService;
