<template>
  <div class="home">
    <b-navbar
    class="position-fixed w-100"
    toggleable="lg"
    type="dark"
    id="menu-top"
    variant="primary">
    <div class="w-100">
     <b-row>
        <b-col>
          <b-button variant="default" class="" @click="toggleMenu">
            <i class="fas fa-bars"></i> MENU
          </b-button>
        </b-col>

        <b-col class="d-flex justify-content-center align-items-center">
          <b-navbar-brand :to="{ name: 'home' }">
            <img src="@/assets/images/logo.svg" id="logo" alt="" class="w-50">
          </b-navbar-brand>
        </b-col>

        <b-col class="d-flex justify-content-end align-items-center">
          <b-button variant="default" @click="logout" class="text-dark">
            <i class="fas fa-sign-out-alt mr-2"></i> Sair
          </b-button>
        </b-col>
      </b-row>
    </div>
    </b-navbar>

  <div id="nav">
    <div class="row">
      <navbar-left :menuHide="menuHide" v-if="show"/>
      <div id="main" class="p-5 mt-4" :class="{ 'main-full': menuHide }">
        <div class="d-flex justify-content-between">
          <h4 style="margin-left: -15px">{{ $route.meta.title }}</h4>
          <b-button variant="default" @click="$router.go(-1)">
            <i class="fas fa-arrow-left"></i> Voltar
          </b-button>
        </div>
        <div class="w-100 mt-3">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import LoginService from '@/Services/LoginService';

export default {
  components: {
    'navbar-left': () => import('@/layout/menu-left.vue'),
  },
  data() {
    return {
      // eslint-disable-next-line no-unneeded-ternary
      menuHide: window.innerWidth > 1366 ? false : true,
      rotate: false,
      mobile: false,
      show: false,
      load: false,
    };
  },
  methods: {
    isMobile() {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width <= 760) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },

    logout() {
      LoginService.logout();
      this.$router.replace('/login');
    },

    toggleMenu() {
      this.menuHide = !this.menuHide;
      this.rotate = !this.rotate;
    },
  },

  watch: {
    $route() {
      if (window.innerWidth > 1366) {
        this.menuHide = false;
      } else {
        this.menuHide = true;
      }
    },
  },
  created() {
    this.isMobile();
    this.show = true;
  },
};
</script>
