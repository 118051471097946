<template>
  <div id="container-login">
    <div class="w-100" id="over">
      <div class="col-md-8" id="content">
        <div class="row">
          <div class="col-md-6 d-flex justify-content-center align-items-center" id="content-logo">
              <img src="@/assets/images/logo.svg" alt="">
          </div>
          <div class="col-md-6" id="content-form">
            <h4 class="text-center">Faça seu Login!</h4>
            <form ref="form" @submit.stop.prevent="login">
              <label for="">Email</label>
              <input class="form-control" type="text" v-model="payload.email">
              <label for="" class="mt-3">Senha</label>
              <input class="form-control" type="password" v-model="payload.password">
              <button type="submit" :disabled="load" class="d-flex align-items-center justify-content-center">
                <b-spinner small class="mr-2" v-show="load"></b-spinner> ENTRAR
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
#content{
  background: #fff;
}

#over{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000c2;
  display: flex;
  justify-content: center;
  align-items: center;
}

#content-form{
  background: #ff660e;
  padding: 100px 15px;
  color: #fff;
}
#container-login{
  background: url(../../assets/images/banner-login.jpg);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
/* img{
    width: 45%;
} */
button{
  border: none;
  background: #0f1d6a;
  color: #fff;
  font-size: 20px !important;
  font-weight: bold;
  padding: 10px;
  width: 100%;
  margin-top: 40px !important;
}
input{
  padding: 25px 10px !important;
}

@media screen and (max-width: 768px) {
  #content-logo{
    padding: 40px;
  }
}
</style>

<script>
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */

import LoginService from '@/Services/LoginService';
import AgentService from '@/Services/AgentService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  data() {
    return {
      payload: {
        email: null,
        password: null,
      },
      load: false,
      agents: {},
    };
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async login() {
      this.load = true;
      try {
        const { data } = await LoginService.login(this.payload);
        LoginService.saveUser(data.user);
        LoginService.saveToken(data.token);
        LoginService.savePermission(data.permission);
        this.$router.push({ name: 'projects' });
        this.messageSuccess('Login realizado com sucesso');
      } catch (error) {
        console.log(error);
        this.messageError('Usuário ou senha inválidos');
      }
      this.load = false;
    },
    async getList() {
      const { data } = await AgentService.getList();
      this.agents = data.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        contact: item.phone_mobile,
        city: item.address.city.name,
        province: item.address.city.province.name,
      }));
    },
  },
};
</script>
